import React, { useEffect, useState } from "react";
import { Badge, CardTitle, Table } from "reactstrap";
import { formatDate, getCookie } from "../../utils/Utils";

let token = process.env.REACT_APP_AUTH_TOKEN;

const TopEventComp = () => {
  return (
    <React.Fragment>
      {/* New Table for Top 3 Companies */}
      <div className="card-title-group align-start mb-3 align-center fs-4 fw-medium">
        <CardTitle>
            Top 3 Companies with Most Events
          {/* <Badge className=" badge-dot badge-lg" color="gray">
          </Badge> */}
        </CardTitle>
      </div>
      <Table className="table-bordered">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Total Events</th>
            <th>Successfull Events </th>
            <th>Cancelled Events</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> Dj David Guetta</td>
            <td>45</td>
            <td>30</td>
            <td>15</td>
          </tr>
          <tr>
            <td> Dj Prathamesh</td>
            <td>30</td>
            <td>22</td>
            <td>8</td>
          </tr>
          <tr>
            <td> Calvin Harris </td>
            <td>24</td>
            <td>18</td>
            <td>6</td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
};
export default TopEventComp;
