import React, { useEffect, useState } from "react";
import Icon from "../../../../components/icon/Icon";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { useThemeUpdate } from "../../../provider/Theme";
import { useQuery, useQueryClient } from "react-query";
import { getNotifications } from "../../../../http/get/getApi";
import data from "./NotificationData";

const NotificationItem = ({ text, time, id, module }) => {
  const date = new Date(time);

  const notificationDate = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const notificationTime = `${formattedHours}:${minutes} ${ampm}`;

  return (
    <div className="nk-notification-item" key={id} id={id}>
      <div className="nk-notification-icon">
        <Icon
          name={
            module === "1" ? "user-add" : module === "2" ? "sign-usd " : module === "3" ? "calendar-booking" : "bell"
          }
        />
      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">{text}</div>
        <div className="d-flex justify-content-between">
          <div className="nk-notification-time">{notificationDate}</div>
          <div className="nk-notification-time">{notificationTime}</div>
        </div>
      </div>
    </div>
  );
};

const Notification = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [limit, setLimit] = useState(3);
  const [lastNotificationCheck, setLastNotificationCheck] = useState(
    localStorage.getItem("lastNotificationCheck") || Date.now()
  );
  const queryClient = useQueryClient();

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setLimit(3);
      }
      return newState;
    });
  };

  const {
    data: notificationsData,
    isLoading: notificationsLoading,
    isError: notificationsError,
  } = useQuery({
    queryKey: ["get-admin-notifications", limit],
    queryFn: () => {
      console.log(`Fetching with limit: ${limit}`);
      return getNotifications({ limit: limit, offset: 0 });
    },
  });

  useEffect(() => {
    localStorage.setItem("lastNotificationCheck", lastNotificationCheck);
  }, [lastNotificationCheck]);

  useEffect(() => {
    if (notificationsData?.data?.length > 0) {
      const hasNewNotifications = notificationsData.data.some(
        (notification) => new Date(notification.created_at).getTime() > lastNotificationCheck
      );
      setHasUnread(hasNewNotifications);
    } else {
      setHasUnread(false);
    }
  }, [notificationsData, lastNotificationCheck]);

  const handleMarked = (e) => {
    e.preventDefault();
    setHasUnread(false);
    setLastNotificationCheck(Date.now());
  };

  const handleViewAll = (e) => {
    e.preventDefault();
    setLimit(10);
    queryClient.invalidateQueries("get-admin-notifications");
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" style={{ margin: 0 }} toggle={toggle}>
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="position-relative">
          <Icon name="bell" />
          {hasUnread && (
            <span
              className="position-absolute bg-danger border border-light rounded-circle"
              style={{
                top: "-0.25rem",
                right: "-0.25rem",
                width: "0.75rem",
                height: "0.75rem",
              }}
            ></span>
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Notifications</span>
          <a href="#markasread" onClick={handleMarked}>
            Mark All as Read
          </a>
        </div>
        <div className="dropdown-body">
          <div className="nk-notification">
            {notificationsLoading ? (
              <div className="d-flex justify-content-center">Loading...</div>
            ) : notificationsData && notificationsData?.data?.length > 0 ? (
              notificationsData?.data?.map((item) => (
                <NotificationItem
                  key={item.id}
                  id={item.id}
                  text={item.description}
                  time={item.created_at}
                  module={item.module}
                />
              ))
            ) : (
              <div className="d-flex justify-content-center">No notifications available</div>
            )}
          </div>
          {notificationsData?.data?.length >= limit && (
            <div className="dropdown-foot">
              <a href="#viewall" onClick={handleViewAll}>
                View All
              </a>
            </div>
          )}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
export default Notification;
