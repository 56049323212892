import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
} from "../../../components/Component";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { set, useForm } from "react-hook-form";
import { Modal, ModalBody } from "reactstrap";
import { getCookie } from "../../../utils/Utils";
import { toast } from "react-toastify";
import Required from "../../components/Required";
import PuffLoader from "react-spinners/PuffLoader";
import { ShimmerTable } from "react-shimmer-effects";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { parsePhoneNumber } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import CompanyListModels from "./CompanyListModels";

let token = process.env.REACT_APP_AUTH_TOKEN;
export const NotAvailable = () => <div className="mx-5">----</div>;

const CompanyInfo = () => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [smOption, setSmOption] = useState(false);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    frm_username: "",
    email: "",
    company_name: "",
    phone_number: "",
    address_1: "",
    created_at: "",
    password: "",
    confirmPassword: "",
    country: "",
  });
  const [editId, setEditedId] = useState();
  const [view, setView] = useState({
    edit: false,
    add: false,
    details: false,
  });
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [totalRecordsShimmer, setTotalRecordsShimmer] = useState(false);
  const [SuspendVal, setSuspendVal] = useState(0);
  const [loadingRow, setLoadingRow] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [phoneValue, setPhoneValue] = useState();
  const [editPhone, setEditPhone] = useState();
  const [sortedCountryCode, setSortedCountryCode] = useState(null);
  const [sortedPhoneNum, setSortedPhoneNum] = useState(null);
  // const password = watch("password");

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/get-companies`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", token),
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setCompanyData(responseData?.data?.list);
      setTotalRecords(responseData?.data?.totalRecords);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //scroll off when sidebar shows
  useEffect(() => {
    view.add ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
  }, [view.add]);

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = companyData.filter((item) => {
        const searchVal = item.company_name;
        return searchVal ? searchVal.toLowerCase().includes(onSearchText.toLowerCase()) : false;
      });
      setCompanyData(filteredObject);
    } else {
      fetchData();
    }
  }, [onSearchText]);
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setView({ edit: false, add: false, details: false });
    resetForm();
  };

  // Function to reset form and state
  const resetForm = () => {
    setFormData({
      frm_username: "",
      email: "",
      company_name: "",
      phone_number: "",
      address_1: "",
      created_at: "",
      password: "",
      confirmPassword: "",
      country: "",
    });
  };

  const onFormSubmit = async (form) => {
    setLoading(true);
    const { frm_username, email, phone_number, company_name, address_1, password, country } = form;
    try {
      const formData = new FormData();
      formData.append("username", frm_username);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      formData.append("company_name", company_name);
      formData.append("address_1", address_1);
      formData.append("password", password);
      formData.append("country", country);
      const companyData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/add-companies`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      }).then((res) => res.json());
      if (companyData && companyData.status) {
        const submittedData = companyData?.data;
        toast.success(`${frm_username} Company Information Added Successfully`);
        fetchData();
        resetForm();
        setLoading(false);
        setView({ edit: false });
      } else {
        toast.error(companyData?.errors?.[0]);
        setLoading(false);
        console.log("data is invalid or missing required properties");
      }
    } catch (error) {
      setLoading(false);
      toast.error(companyData?.errors?.[0]);
      console.log("Error fetching user data:", error);
    }
  };

  // Function to handle editing an existing company
  const onEditSubmit = async (form) => {
    console.log("hello!");
    setLoading(true);
    const { id, frm_username, email, phone_number, company_name, address_1, country } = form;
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("first_name", frm_username);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      formData.append("company_name", company_name);
      formData.append("address_1", address_1);
      formData.append("country", country);
      const companyData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/edit-companies`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", token),
        },
        body: formData,
      }).then((res) => res.json());
      console.log(companyData);
      if (companyData && companyData.status === true) {
        console.log(companyData.status);
        const submittedData = companyData?.data;
        console.log(companyData?.data);
        toast.success(`${frm_username} Company Information Updated Successfully`);
        fetchData();
        resetForm();
        setView({ edit: false });
        setLoading(false);
      } else {
        console.log(companyData?.data);

        toast.error(companyData.message);
        console.log("data is invalid or missing required properties");
        setLoading(false);
      }
    } catch (error) {
      toast.error(companyData.message);
      console.log("Error fetching user data:", error);

      setLoading(false);
    }
  };

  const onEditClick = (id) => {
    companyData.forEach((item) => {
      setEditPhone(item.phone_number);
      if (item.id === id) {
        setFormData({
          id: item.id,
          frm_username: item.first_name,
          email: item.email,
          phone_number: item.phone_number,
          company_name: item.company_name,
          address_1: item.address_1,
          password: item.password,
          country: item.country,
          created_at: item.created_at,
        });
      }
    });
    setEditedId(id);
    setFiles([]);
    setView({ add: false, edit: true });
  };

  // function to delete a product
  const deleteProduct = (id) => {
    let defaultData = data;
    defaultData = defaultData.filter((item) => item.id !== id);
    setData([...defaultData]);
  };

  // toggle function to view product details
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
    });
  };

  const chkSuspend = async (id, val) => {
    setLoadingRow(true);
    setCompanyId(id);
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("is_suspend", val);
      const SuspendResult = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/edit-companies`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formData,
      });
      if (SuspendResult && SuspendResult.status) {
        // const submittedData = SuspendResult?.data;
        toast.success(`Company Dashboard Status Updated`);
        fetchData();
        setLoadingRow(false);
        setCompanyId("");
      } else {
        toast.error(SuspendResult.message);
        setLoadingRow(false);
        setCompanyId("");
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
      setLoadingRow(false);
      setCompanyId("");
    }
  };
  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = companyData?.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    setTableLoading(true);
    setTimeout(() => {
      setTableLoading(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <Head title="Product List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Company Details</BlockTitle>
              <div className="d-flex flex-row">
                <span>Total Companies registration :</span>
                <span className="text-primary fw-semibold px-1">{tableLoading ? 0 : totalRecords}</span>
              </div>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div className="toggle-expand-content" style={{ display: smOption ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-sm w-250px"
                          id="default-04"
                          placeholder="Quick search by Company Name"
                          // onChange={(e) => onFilterChange(e)}
                          onChange={(e) => {
                            setTimeout(() => {
                              onFilterChange(e);
                            }, 500);
                          }}
                        />
                      </div>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                      <Button
                        className="toggle d-none d-md-inline-flex"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                          resetForm();
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Company</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered overflow-auto">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                {tableLoading ? (
                  <div className="p-1">
                    <ShimmerTable row={6} col={7} />
                  </div>
                ) : (
                  <DataTableBody>
                    <DataTableHead>
                      <DataTableRow className="fw-medium fs-5 text-center">
                        <span>Company Id</span>
                      </DataTableRow>
                      <DataTableRow className=" fs-5 text-center">
                        <span>Company Name</span>
                      </DataTableRow>
                      <DataTableRow className=" fs-5 text-center">
                        <span>Email</span>
                      </DataTableRow>
                      <DataTableRow className=" fs-5 text-center">
                        <span>Contact Number</span>
                      </DataTableRow>
                      <DataTableRow className=" fs-5 text-center">
                        <span>Actions</span>
                      </DataTableRow>
                      {/* <DataTableRow className=" fs-5 text-center d-none">
                        <span>Access Dashboard</span>
                      </DataTableRow> */}
                    </DataTableHead>
                    {currentItems &&
                      currentItems.map((item, idx) => {
                        return (
                          <DataTableItem
                            key={item.id}
                            className={item?.is_suspend === "1" ? "bg-danger text-white " : "text-black"}
                          >
                            {loadingRow && companyId === item.id ? (
                              <>
                                <DataTableRow className="nk-tb-item"></DataTableRow>
                                <DataTableRow className="nk-tb-item"></DataTableRow>
                                <DataTableRow className="nk-tb-item">
                                  <div className="justify-center">
                                    <PuffLoader speedMultiplier="2" size="40" color={"#39ff00"} />
                                  </div>
                                </DataTableRow>
                                <DataTableRow className="nk-tb-item"></DataTableRow>
                                <DataTableRow className="nk-tb-item"></DataTableRow>
                                <DataTableRow className="nk-tb-item"></DataTableRow>
                              </>
                            ) : (
                              <>
                                <DataTableRow className="text-center">
                                  <span className="tb-sub">
                                    <span className="tb-sub fs-6 text-body">{item?.id || <NotAvailable />}</span>
                                  </span>
                                </DataTableRow>
                                <DataTableRow className="text-center">
                                  <span className="tb-sub">
                                    <span className="title fs-6 text-body">{item.company_name || <NotAvailable />}</span>
                                  </span>
                                </DataTableRow>
                                <DataTableRow className="text-center">
                                  <span className="tb-sub fs-6 text-body">{item.email || <NotAvailable />}</span>
                                </DataTableRow>
                                <DataTableRow className="text-center">
                                  <span className="tb-sub fs-6 text-body">{item.phone_number || <NotAvailable />}</span>
                                </DataTableRow>
                                <DataTableRow className="nk-tb-col-tools text-center">
                                  <ul className="gx-1 my-n1">
                                    <li className="me-n1">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="a"
                                          href="#more"
                                          onClick={(ev) => ev.preventDefault()}
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                        >
                                          <Icon
                                            name="more-h"
                                            className={item?.is_suspend === "1" ? "text-white" : ""}
                                          ></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                          <ul className="link-list-opt no-bdr">
                                            <li style={{ cursor: "pointer" }}>
                                              <DropdownItem
                                                tag="a"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  onEditClick(item.id);
                                                  toggle("edit");
                                                }}
                                              >
                                                <Icon name="edit"></Icon>
                                                <span>Edit Company</span>
                                              </DropdownItem>
                                            </li>
                                            <li style={{ cursor: "pointer" }}>
                                              <DropdownItem
                                                tag="a"
                                                onClick={() => navigate(`/company-profile?id=${item?.id}`)}
                                              >
                                                <Icon name="eye"></Icon>
                                                <span>View Profile</span>
                                              </DropdownItem>
                                            </li>
                                            <li style={{ cursor: "pointer" }}>
                                              {item?.is_suspend === "1" ? (
                                                <DropdownItem
                                                  className="text-danger"
                                                  tag="a"
                                                  onClick={() => {
                                                    chkSuspend(item?.id, 0);
                                                  }}
                                                >
                                                  <Icon name="file-check" className="fs-4"></Icon>
                                                  <span>Activate Company</span>
                                                </DropdownItem>
                                              ) : (
                                                <DropdownItem
                                                  tag="a"
                                                  onClick={() => {
                                                    chkSuspend(item?.id, 1);
                                                  }}
                                                >
                                                  <Icon name="file-remove" className="fs-4"></Icon>
                                                  <span>Suspend Company</span>
                                                </DropdownItem>
                                              )}
                                            </li>
                                          </ul>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </li>
                                  </ul>
                                </DataTableRow>
                                {/* <DataTableRow className="justify-center">
                                  <Button color="primary" type="submit">
                                    <span className="px-0 mx-0">Access </span>
                                     {loading ? <PuffLoader speedMultiplier="2" size="30" color={"#39ff00"} /> : ""} 
                                  </Button>
                                </DataTableRow> */}
                              </>
                            )}
                          </DataTableItem>
                        );
                      })}
                  </DataTableBody>
                )}

                <div className="card-inner">
                  {companyData?.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={companyData.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Block>
        <CompanyListModels
          view={view.add}
          modalType="add"
          formData={formData}
          setFormData={setFormData}
          onFormCancel={onFormCancel}
          onSubmit={(formData) => onFormSubmit(formData)}
          setLoading={setLoading}
          loading={loading}
          setPhoneValue={setPhoneValue}
          phoneValue={phoneValue}
        />
        <CompanyListModels
          view={view.edit}
          modalType="edit"
          formData={formData}
          setFormData={setFormData}
          onFormCancel={onFormCancel}
          onSubmit={onEditSubmit}
          setLoading={setLoading}
          loading={loading}
          setPhoneValue={setPhoneValue}
          phoneValue={phoneValue}
          editPhone={editPhone}
          // setSortedPhoneNum={setSortedPhoneNum}
          // sortedPhoneNum={sortedPhoneNum}
          // setSortedCountryCode={setSortedCountryCode}
          // sortedCountryCode={sortedCountryCode}
        />
      </Content>
    </React.Fragment>
  );
};

export default CompanyInfo;
