import React from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import AppDropdown from "./dropdown/app/App";
import logo from "../../images/favicon.png";

import { useTheme, useThemeUpdate } from "../provider/Theme";

const AppHeader = ({ fixed, className, app }) => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  const textColorClass = classNames({
    "text-primary": theme.header === "dark",
    "text-black": theme.header === "white" || theme.header === "light",
  });

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          {/* <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
          </div> */}
          <div className="nk-header-app-name">
            {/* <div className="nk-header-app-logo">
              <img src={logo} alt="DJ_logo" />
            </div> */}
            <div className="nk-header-app-info">
              <span className="sub-text fs-6 fw-medium text-primary">Admin</span>
              <span className="lead-text fs-5">{app.text}</span>
            </div>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="notification-dropdown me-n1">
                <Notification />
              </li>
              <li className="list-apps-dropdown d-lg-none">
                <AppDropdown />
              </li>
              <li className="user-dropdown">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppHeader;
