import React, { useEffect, useState } from "react";
import { Badge, CardTitle, Col, Row } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  ProjectCard,
  TooltipComponent,
} from "../../components/Component";
import { getCookie } from "../../utils/Utils";
import { ShimmerThumbnail } from "react-shimmer-effects";

let token = process.env.REACT_APP_AUTH_TOKEN;

const FinancialOverview = () => {
  return (
    <React.Fragment>
      <Block>
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h6" className="d-flex justify-content-between">
              {/* <Badge className=" badge-dot badge-lg" color="gray">
              </Badge> */}
              <CardTitle className="fs-4 fw-medium">Financials Overview</CardTitle>
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <>
          <div className="row text-center justify-around">
            <div className="nk-sale-data col-auto">
              <span className="fw-bold">Total Revenue</span>
              <span className="amount fs-3">$10000</span>
              <span className="sub-title"></span>
            </div>
            <div className="nk-sale-data col-auto">
              <span className="fw-bold">Upcoming Event Revenue</span>
              <span className="amount fs-3">$2000</span>
              <span className="sub-title"></span>
            </div>
            <div className="nk-sale-data col-auto">
              <span className="fw-bold">Completed Payments</span>
              <span className="amount fs-3">$1200</span>
              <span className="sub-title">
                <span className="change down text-danger"></span>
              </span>
            </div>
            <div className="nk-sale-data col-auto">
              <span className="fw-bold">Pending Payments</span>
              <span className="amount fs-3">$800</span>
              <span className="sub-title">
                <span className="change down text-danger"></span>
              </span>
            </div>
          </div>
        </>
      </Block>
    </React.Fragment>
  );
};

export default FinancialOverview;
