import React, { useEffect, useState } from "react";
import { Button, Card, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  BlockBetween,
  BlockDes,
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Col,
  Row,
  Icon,
} from "../../../components/Component";
import { toast } from "react-toastify";
import { formatDate, getCookie } from "../../../utils/Utils";
import Paypal from "./Paypal";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import StripePayment from "./StripePayment";
import { motion } from "framer-motion";
import { fetchPlanData } from "../../../http/get/getApi";
import { useQuery, useQueryClient } from "react-query";
import Aos from "aos";
import "aos/dist/aos.css";
// import { sendSMS } from "../../../http/SMS/sms";
// import SMSForm from "../../../http/SMS/sms";

let token = process.env.REACT_APP_AUTH_TOKEN;

const PlanRegistration = () => {
  const [data, setData] = useState(null);
  const [dataId, setDataId] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [modalTab, setModalTab] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [planName, setPlanName] = useState(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [planEndDate, setPlanEndDate] = useState(null);
  const [freePlan, setFreePlan] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-subscription-plans`, {
        method: "POST",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.plans);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const {
    data: fetchedPlanData,
    isLoading: planDataLoading,
    isError: planDataError,
  } = useQuery({
    queryKey: ["get-planData"],
    queryFn: () => fetchPlanData(),
  });

  useEffect(() => {
    if (fetchedPlanData?.status === true) {
      fetchData();
      subscribedPlanAPI();
    } else {
      fetchData();
    }
    Aos.init({ duration: 1000 });
    return () => {};
  }, []);

  const invalidPlanData = () => {
    queryClient.invalidateQueries("get-planData");
  };

  const subscribedPlanAPI = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-plan`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", token),
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setDataId(responseData?.data?.package?.plan_data?.id);
        setPlanEndDate(fetchedPlanData?.data?.package?.end_date);
        setFreePlan(fetchedPlanData?.data?.package?.plan_data?.is_paid);
      } else {
        throw new Error("Failed to fetch plan data");
      }
      return response;
    } catch (error) {
      console.error("Error fetching plan data:", error);
    }
  };

  const handleSubmitSubscribePayment = async (status, payment_txn_id, payment_response, amount, mode) => {
    let add_status;
    const plan_id = selectedPlan?.id;
    const note = selectedPlan?.name + " plan purchased";
    const payer_id = getCookie("user_id");
    if (status === "COMPLETED") {
      add_status = "3";
    } else if (!status === "COMPLETED") {
      toast.success(note + "Trasaction is not completed");
    }
    if (plan_id === undefined || payment_txn_id === undefined) {
      toast.error("Something went to wrong! Contact to adminstrator");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("status", add_status);
      formData.append("payment_txn_id", payment_txn_id);
      formData.append("payment_response", JSON.stringify(payment_response));
      formData.append("amount", amount);
      formData.append("payer_id", payer_id);
      formData.append("payment_mode", mode);
      formData.append("note", note);
      formData.append("purpose", "1");
      formData.append("mode", "3");
      formData.append("plan_id", plan_id);
      const planData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/subscribe-package`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken", token),
        },
      });
      if (planData && planData.status) {
        const submittedData = planData?.data;
        invalidPlanData();
        toast.success("Plan Purchased Successfully");
        // setTimeout(() => {
        //   navigate("/dashboard");
        // }, 3000);
      } else {
        console.log("planUser data is invalid or missing required properties");
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
    }
  };

  const handleSubmitFreeSubscribePayment = async (planId) => {
    if (planId === undefined) {
      toast.error("Something went to wrong! Contact to adminstrator");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("plan_id", planId);
      const planData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/subscribe-free-package`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken", token),
        },
      });
      if (planData && planData.status) {
        const submittedData = planData?.data;
        invalidPlanData();
        toast.success("Free-Plan Purchased Successfully");
        // const number = "1234567890"
        // const message = note + "purchased successfully";
        // <SMSForm number={number} message={message}/>
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      } else {
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };
  // const handleSubmitSubscribePayment = async (payment_txn_id, mode) => {
  //   const txn_id = payment_txn_id;

  //   try {
  //     const formData = new FormData();
  //     formData.append("plan_id", plan_id);
  //     formData.append("txn_id", txn_id);
  //     formData.append("payment_mode", mode);

  //     const planData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/subscribe-package`, {
  //       method: "POST",
  //       body: formData,
  //       headers: {
  //         authToken: getCookie("authToken"),
  //       },
  //     });
  //     if (planData && planData.status) {
  //       const submittedData = planData.data;
  //       toast.success("Plan Subscribed Successfully");
  //       setModalTab(false);

  //       setPlanData(submittedData);
  //     } else {
  //       console.log("planUser data is invalid or missing required properties");
  //       toast.error(planData.message);
  //     }
  //   } catch (error) {
  //     toast.error("Something went to wrong!");
  //     console.log("Error fetching user data:", error);
  //   } finally {
  //   }
  // };
  const toggleTab = (plan) => {
    setModalTab(!modalTab);
    setSelectedPlan(plan);
  };

  //  const stripPayment = async (amount) => {
  //     const note = selectedPlan?.name + " plan purchased";
  //     const payer_id = getCookie("user_id");

  //     try {
  //       const formData = new FormData();
  //       formData.append("stripeToken", "tok_1PA8aBAcvRDN3zo6Aiyg8y4z");
  //       formData.append("amount", amount);
  //       formData.append("note", note);
  //       formData.append("purpose", "1");
  //       formData.append("payer_id", payer_id);
  //       const planData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/payment/stripe-payment`, {
  //         method: "POST",
  //         body: formData,
  //         headers: {
  //           authToken: getCookie("authToken"),
  //         },
  //       });
  //       if (planData && planData.status) {
  //         const submittedData = planData.data;
  //         toast.success("Plan Purchased Successfully");
  //         handleSubmitSubscribePayment(payment_txn_id, "1");
  //       } else {
  //         console.log("planUser data is invalid or missing required properties");
  //         toast.error(planData.message);
  //       }
  //     } catch (error) {
  //       toast.error("Something went to wrong!");
  //       console.log("Error fetching user data:", error);
  //     }
  //   };

  const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  const BtnHover = {
    hover: {
      y: 1,
    },
  };

  return (
    <React.Fragment>
      <Head title="Pricing Table"></Head>
      <Content>
        <BlockHead size="sm" className="d-flex justify-between">
          <BlockBetween className="g-3">
            <BlockContent>
              <BlockTitle>Select the Plan</BlockTitle>
              <BlockDes className="text-soft">
                <p>Choose the perfect plan that fits your needs and budget.</p>
              </BlockDes>
            </BlockContent>
          </BlockBetween>
          {freePlan === "0" ? (
            <>
              <Card
                className={
                  new Date(planEndDate) > new Date()
                    ? "border-3 border-primary round-xl"
                    : new Date(planEndDate) < new Date()
                    ? "border-3 border-danger round-xl"
                    : ""
                }
              >
                <BlockBetween className="g-3 mx-1 my-1">
                  <BlockContent>
                    <BlockTitle className="fs-4 text-center">
                      Free Plan{" "}
                      {new Date(planEndDate) > new Date() ? (
                        <span className="fs-3 text-primary" data-aos="fade-up">
                          Activated
                        </span>
                      ) : new Date(planEndDate) < new Date() ? (
                        <span className="fs-3 text-danger" data-aos="fade-up">
                          Subscritpion Ended
                        </span>
                      ) : (
                        <></>
                      )}{" "}
                    </BlockTitle>
                    <BlockDes className="text-soft">
                      <p>This plan is Activated until the {planEndDate}.</p>
                    </BlockDes>
                  </BlockContent>
                </BlockBetween>
              </Card>
            </>
          ) : (
            <></>
          )}
        </BlockHead>

        <Block>
          <Row className="g-gs justify-center">
            {data &&
              data.map((item) => {
                if (item.status === "1") {
                  if (dataId && item.is_paid === "0") {
                    return null;
                  }
                  return (
                    <Col md={6} lg={3} key={item.id}>
                      <Card
                        className={
                          dataId === item.id && new Date(planEndDate) > new Date()
                            ? "border-3 border-primary round-xl"
                            : dataId === item.id && new Date(planEndDate) < new Date()
                            ? "border-3 border-danger round-xl"
                            : ""
                        }
                      >
                        {dataId === item.id ? (
                          <>
                            {new Date(planEndDate) > new Date() ? (
                              <>
                                <div className="fw-semibold text-white fs-5 bg-primary text-center py-2">
                                  <span data-aos="fade-down">
                                    <Icon name="star-round" /> Current Subscribed Plan
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="fw-semibold text-white fs-5 bg-danger text-center py-2">
                                  <span data-aos="fade-down">
                                    <Icon name="cross-circle" /> Current Plan Expired
                                  </span>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <div className={dataId === item.id ? "pricing-head pt-3 p-0" : "pricing-head"}>
                          <div className="pricing-title">
                            <h4 className="card-title title">{item.name} </h4>
                          </div>
                          <div className="card-text">
                            <Row>
                              <Col size={6}>
                                {item?.is_paid === "0" ? (
                                  <span className="h5 fw-400">Free</span>
                                ) : (
                                  <span className="h5 fw-400">$ {item?.price}</span>
                                )}
                                <span className="sub-text fs-5">Plan price</span>
                              </Col>
                              <Col size={6}>
                                <span className="h5 fw-400">{item?.duration}</span>
                                {item?.duration_type === "m" ? (
                                  <span className="sub-text fs-5">Month</span>
                                ) : (
                                  <span className="sub-text fs-5">Days</span>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="pricing-body">
                          <ul className="pricing-features">
                            <li>
                              <span className="w-50">Employee</span> -{" "}
                              <span className="ms-auto">{item?.features?.employee}</span>
                            </li>
                            <li>
                              <span className="w-50">Client</span> -{" "}
                              <span className="ms-auto">{item?.features?.client}</span>
                            </li>
                            <li>
                              <span className="w-50">Event Limit</span> -{" "}
                              <span className="ms-auto">{item?.features?.event_limit}</span>
                            </li>
                            <li>
                              <span className="w-50">Management</span> -{" "}
                              <span className="ms-auto">{item?.features?.management}</span>
                            </li>
                            <li>
                              <span className="w-50">Technical Support</span> -{" "}
                              <span className="ms-auto">{item?.features?.technical_support}</span>
                            </li>
                            <li>
                              <span className="w-50">Website Tool</span> -{" "}
                              <span className="ms-auto">{item?.features?.website_tool}</span>
                            </li>
                            <li>
                              <span className="w-50">Enhanced Support</span> -{" "}
                              <span className="ms-auto">{item?.features?.enhanced_support}</span>
                            </li>
                            <li>
                              <span className="w-50">Advanced Features</span> -{" "}
                              <span className="ms-auto">{item?.features?.advanced_features}</span>
                            </li>
                          </ul>
                          <div className={dataId === item.id ? "pricing-action mt-3 m-0" : "pricing-action"}>
                            <Button color="primary" onClick={() => toggleTab(item)}>
                              {item?.is_paid === "0" ? (
                                <motion.span variants={BtnHover} whileHover={"hover"}>
                                  <span className="sub-text text-white">Try Now</span>
                                </motion.span>
                              ) : (
                                <motion.span variants={BtnHover} whileHover={"hover"}>
                                  <span className="sub-text text-white">
                                    {/* {} */}
                                    {!dataId ? "Buy Now" : item?.id === dataId ? "Renew Now" : "Upgrade Now"}
                                  </span>
                                </motion.span>
                              )}
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  );
                }
              })}
          </Row>
        </Block>

        <Modal size="lg" isOpen={modalTab} toggle={toggleTab}>
          <ModalHeader
            toggle={toggleTab}
            close={
              <button className="close" onClick={toggleTab}>
                <Icon name="cross" />
              </button>
            }
          >
            Select the Payment Method
          </ModalHeader>
          <ModalBody>
            <Nav tabs className="mt-n3">
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeTab === "1" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setActiveTab("1");
                  }}
                >
                  Paypal
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeTab === "2" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setActiveTab("2");
                  }}
                >
                  Card
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="d-flex justify-between">
                  {selectedPlan?.is_paid === "1" ? (
                    <>
                      <span className="fw-bold fs-5">Purchase with</span>
                      <div>
                        <Paypal
                          handleSubmitSubscribePayment={handleSubmitSubscribePayment}
                          price={selectedPlan?.price}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <span className="fw-bold fs-5">To access the free plan</span>
                      <div>
                        <Button
                          color="primary"
                          onClick={() => handleSubmitFreeSubscribePayment(selectedPlan?.id)}
                          disabled={loading}
                        >
                          {loading ? "Loading..." : " Trial Plan"}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="2">
                    <StripePayment price={selectedPlan?.price} name={selectedPlan?.name} />
                  </TabPane>
                </TabContent>
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default PlanRegistration;
