import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import AppMain from "./global/AppMain";
import AppRoot from "./global/AppRoot";
import AppHeader from "./header/AppHeader";
import Head from "./head/Head";
import { fetchPlanData } from "./../http/get/getApi";
import { ShimmerCircularImage, ShimmerTable, ShimmerThumbnail } from "react-shimmer-effects";
import { Col, Row } from "reactstrap";
import { useQuery } from "react-query";

let token = process.env.REACT_APP_AUTH_TOKEN;

const Layout = ({ title, app }) => {
  const [planData, setPlanData] = useState(null);
  const [planState, setPlanState] = useState();

  //fetching countries data
  const {
    data: fetchedPlanData,
    isLoading: planDataLoading,
    isError: planDataError,
  } = useQuery({
    queryKey: ["get-planData"],
    queryFn: () => fetchPlanData(),
  });

  useEffect(() => {
    if (!planDataLoading && !planDataError && fetchedPlanData) {
      if (fetchedPlanData.status && new Date(fetchedPlanData?.data?.package.end_date) > new Date()) {
        setPlanData(fetchedPlanData.data.plans);
        setPlanState("true");
      } else {
        setPlanState("false");
      }
    }
  }, [fetchedPlanData, planDataLoading, planDataError]);

  return (
    <>
      <Head title={title || "Loading..."} />
      {planDataLoading ? (
        <div className="p-1">
          <Row>
            <Col md={1} className=" border p-1 bg-white">
              <div className="sidebar px-3 ">
                <div className="logo my-2">
                  <ShimmerThumbnail height={100} width={100} rounded />
                </div>
                <div className="icons px-1 my-3">
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                  <ShimmerThumbnail height={40} rounded />
                </div>
              </div>
            </Col>
            <Col md={11} className="border p-1 bg-white">
              <Row>
                <div className="d-flex justify-content-between my-2">
                  <div>
                    <ShimmerThumbnail height={50} width={100} rounded />
                  </div>
                  <div className="d-flex align-item-center">
                    <div className="mx-2">
                      <ShimmerCircularImage size={50} />
                    </div>
                    <div className="mx-2">
                      <ShimmerCircularImage size={50} />
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <div className="d-flex justify-content-between my-2">
                  <div>
                    <ShimmerThumbnail height={50} width={200} rounded />
                  </div>
                  <div className="d-flex align-item-center">
                    <div className="mx-2">
                      <ShimmerThumbnail height={50} width={100} rounded />
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <div className="my-2">
                  <ShimmerTable row={10} />;
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <AppRoot>
          <Appbar />
          <AppMain>
            <AppHeader app={app} />
            <Outlet />
          </AppMain>
        </AppRoot>
      )}
    </>
  );
};

export default Layout;
