import React, { useEffect, useState } from "react";
import FileSelection2 from "./FileSelection2";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { getCookie } from "../../../utils/Utils";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { toast } from "react-toastify";
import PuffLoader from "react-spinners/PuffLoader";
import { useQuery, useQueryClient } from "react-query";
import { BlockTitle } from "../../../components/Component";

let token = process.env.REACT_APP_AUTH_TOKEN;

const LogoChange = () => {
  const [data, setData] = useState(null);
  const [btnloader, setButtonloader] = useState(false);
  const queryClient = useQueryClient();

  //site setting
  const {
    data: logosData,
    isLoading: logosLoading,
    isError: logosError,
  } = useQuery({
    queryKey: ["get-logos"],
    queryFn: () => fetchData(),
  });

  const fetchData = async () => {
    try {
      const formData = new FormData();
      // formData.append("field_id", "favicon");
      // formData.append("field_id", "sidebar_logo");
      // formData.append("field_id", "client_home_logo");
      // formData.append("field_id", "public_logo");
      // formData.append("field_id", "logo");
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-site-settings`, {
        method: "POST",
        // body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = () => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You won't be able to delete it",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes change it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setButtonloader(true);
        const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];

        const formData = new FormData();
        const isValidFileType = (file) => allowedFileTypes.includes(file.type);

        const handleFileInput = (inputElement, formDataKey) => {
          const file = inputElement.files[0];
          if (file) {
            if (isValidFileType(file)) {
              formData.append(formDataKey, file);
            } else {
              toast.error(`Invalid file type: ${file.type}. Allowed types are jpg, png, jpeg, gif.`);
            }
          }
        };
        handleFileInput(document.getElementById("sidebarLogoId"), "sidebar_logo");
        handleFileInput(document.getElementById("faviconLogoId"), "favicon_logo");
        handleFileInput(document.getElementById("publicpageLogoId"), "public_page_logo");
        handleFileInput(document.getElementById("clienthomepageLogoId"), "client_home_page_logo");
        handleFileInput(document.getElementById("authLogoId"), "auth_logo");
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/sitesetting/add-sitesetting`, {
            method: "POST",
            headers: {
              authToken: getCookie("authToken", token),
            },
            body: formData,
          });

          if (response.ok) {
            toast.success("Files uploaded successfully!");
          } else {
            const errorData = await response.json();
            toast.error(`Error: ${errorData.message || "Failed to upload files"}`);
          }
        } catch (error) {
          console.error("API call error:", error);
          toast.error("An error occurred during file upload.");
        } finally {
          setButtonloader(false);
          queryClient.invalidateQueries("get-logos");
        }
      }
    });
  };

  return (
    <>
      {/* <div className=""> */}
      <div className="row my-3 mx-1">
        <BlockTitle tag="h4">Logo Selection</BlockTitle>
        <span>Logo Selection for specific sections</span>
      </div>
      {logosLoading ? (
        <ShimmerSimpleGallery card imageHeight={250} caption row={1} col={4} />
      ) : (
        <div className="row border border-grey mx-2 my-2 d-flex justify-center bg-body-secondary">
          {data && (
            <>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-start flex-column my-2">
                  <div className="fs-18px fw-bold">Sidebar Logo</div>
                  <FileSelection2
                    filePath={`${process.env.REACT_APP_API_URL}` + data?.site_settings?.[1]?.field_value}
                    inputId="sidebarLogoId"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-center flex-column my-2">
                  <div className="fs-18px fw-bold">Favicon Logo</div>
                  <FileSelection2
                    filePath={`${process.env.REACT_APP_API_URL}` + data?.site_settings?.[0]?.field_value}
                    inputId="faviconLogoId"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-center flex-column my-2">
                  <div className="fs-18px fw-bold">Public Page Logo</div>
                  <FileSelection2
                    filePath={`${process.env.REACT_APP_API_URL}` + data?.site_settings?.[3]?.field_value}
                    inputId="publicpageLogoId"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-center flex-column my-2">
                  <div className="fs-18px fw-bold">Client Dashboard Logo</div>
                  <FileSelection2
                    filePath={`${process.env.REACT_APP_API_URL}` + data?.site_settings?.[2]?.field_value}
                    inputId="clienthomepageLogoId"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-center flex-column my-2">
                  <div className="fs-18px fw-bold">Auth Pages Logo</div>
                  <FileSelection2
                    filePath={`${process.env.REACT_APP_API_URL}` + data?.site_settings?.[4]?.field_value}
                    inputId="authLogoId"
                  />
                </div>
              </div>
            </>
          )}
          <div className="row my-2">
            <div className="d-flex align-items-center justify-content-center">
              <Button color="primary" onClick={handleSubmit} disabled={btnloader}>
                {btnloader ? <PuffLoader speedMultiplier={3} size={25} color="#39ff00" /> : "SAVE"}
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* </div> */}
    </>
  );
};

export default LogoChange;
