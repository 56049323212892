import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";
import FileManager from "../pages/Home Page Setup/Homepage";
import FileManagerFiles from "../pages/Home Page Setup/Homepage";
import FileManagerShared from "../pages/Home Page Setup/Homepage";
import FileManagerStarred from "../pages/Home Page Setup/Homepage";
import FileManagerRecovery from "../pages/Home Page Setup/Homepage";
import FileManagerSettings from "../pages/Home Page Setup/Homepage";
import LayoutApp from "../layout/Index-app";
import ProjectCardPage from "../pages/prebuilt/projects/ProjectCard";
import LogoChange from "../pages/prebuilt/Logo Separation/LogoChange";
import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import UserProfileRegular from "../pages/prebuilt/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/prebuilt/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/prebuilt/user-manage/UserProfileNotification";
import EventListPage from "../pages/EventList/EventListPage";
import CompanyInfo from "../pages/prebuilt/Company List/CompanyInfo";
import PlanRegistration from "../pages/prebuilt/Registration Plans/PlanRegistration";
import HomePage from "../pages/Home Page Setup/Homepage";
import Planpayment from "../pages/prebuilt/Payment Details/Planpayment";
import ManageProfiles from "../pages/prebuilt/Company Profile/ManageProfiles";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<Layout app={{ icon: "dashboard", theme: "bg-purple-dim", text: "Dashboard" }} />}
      >
        <Route index element={<HomePage />}></Route>
        <Route path="dashboard" element={<HomePage />}></Route>

        <Route path="event-list">
          <Route index element={<EventListPage />} />
        </Route>

        {/* pre built pages  */}
        <Route path="project-card" element={<ProjectCardPage />}></Route>
        <Route path="site-setting" element={<LogoChange />}></Route>
        <Route path="company-list" element={<CompanyInfo />}></Route>
        <Route path="plan-selection" element={<PlanRegistration />}></Route>
        <Route path="payment-details" element={<Planpayment />}></Route>
        <Route path="company-profile" element={<ManageProfiles />}></Route>
        {/* <Route path="stripe" element={< />}></Route> */}

        <Route>
          <Route path="user-profile-notification" element={<UserProfileNotification />}></Route>
          <Route path="user-profile-regular" element={<UserProfileRegular />}></Route>
          {/* <Route path="user-profile-activity" element={<UserProfileActivity />}></Route> */}
          <Route path="user-profile-setting" element={<UserProfileSetting />}></Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`}>
        <Route element={<LayoutApp app={{ icon: "folder", theme: "bg-orange-dim", text: "File Manager" }} />}>
          <Route path="app-file-manager">
            <Route index element={<FileManager />}></Route>
            <Route path="files" element={<FileManagerFiles />}></Route>
            <Route path="starred" element={<FileManagerStarred />}></Route>
            <Route path="shared" element={<FileManagerShared />}></Route>
            <Route path="recovery" element={<FileManagerRecovery />}></Route>
            <Route path="settings" element={<FileManagerSettings />}></Route>
          </Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
